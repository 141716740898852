// src/components/Navbar.js
import React, { useState } from "react";
import {
  AppBar,
  Toolbar,
  Box,
  IconButton,
  Select,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";
import AccountCircle from "@mui/icons-material/AccountCircle";
import { useNavigate } from "react-router-dom";
import logo from "../logo.png";
import { useSnackbar } from "../contexts/SnackbarContext";

const Navbar = ({ onToggleDarkMode, isDataModified, setIsDataModified }) => {
  const navigate = useNavigate();
  const isLoggedIn = !!localStorage.getItem("token");
  const user = JSON.parse(localStorage.getItem("user"));
  const accountingYears =
    isLoggedIn && user.company.accounting_years
      ? user.company.accounting_years
      : [];
  const [accountingYearId, setAccountingYearId] = useState(
    isLoggedIn ? localStorage.getItem("accountingYear") : ""
  );
  const [anchorEl, setAnchorEl] = useState(null);
  const { showSnackbar } = useSnackbar();

  const handleYearChange = (e) => {
    if (isDataModified) {
      const confirmLeave = window.confirm(process.env.REACT_APP_UNSAVED_PROMPT);
      if (!confirmLeave) {
        e.preventDefault();
        return;
      }

      setIsDataModified(false);
    }

    localStorage.setItem("accountingYear", e.target.value);
    setAccountingYearId(e.target.value);
    navigate("/dashboard");
  };

  const handleMenuOpen = (e) => {
    setAnchorEl(e.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = (e) => {
    if (isDataModified) {
      const confirmLeave = window.confirm(process.env.REACT_APP_UNSAVED_PROMPT);
      if (!confirmLeave) {
        e.preventDefault();
        return;
      }
    }

    handleMenuClose();
    localStorage.removeItem("user");
    localStorage.removeItem("token");
    localStorage.removeItem("accountingYear");
    showSnackbar("Du er nu logget ud", "success");
    navigate("/logind");
  };

  const handleLogoClick = (e) => {
    if (isDataModified) {
      const confirmLeave = window.confirm(process.env.REACT_APP_UNSAVED_PROMPT);
      if (!confirmLeave) {
        e.preventDefault();
        return;
      }

      setIsDataModified(false);
    }

    if (isLoggedIn) {
      navigate("/dashboard");
      return;
    }

    navigate("/");
  };

  const handleSettingsClick = (e) => {
    if (isDataModified) {
      const confirmLeave = window.confirm(process.env.REACT_APP_UNSAVED_PROMPT);
      if (!confirmLeave) {
        e.preventDefault();
        return;
      }

      setIsDataModified(false);
    }

    handleMenuClose();
    navigate("/indstillinger");
  };

  // Added to remove the navbar if the user is not logged in
  if (!isLoggedIn) {
    return null;
  }

  if (accountingYearId === "" && accountingYears.length > 0) {
    setAccountingYearId(accountingYears[0].id);
    localStorage.setItem("accountingYear", accountingYears[0].id);
  }

  return (
    <AppBar position="static" color="transparent" elevation={3}>
      <Toolbar>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between", // This makes sure the logo is on the left and the right elements on the right
            alignItems: "center",
            width: "100%", // Take up the full width of the navbar
            gap: 2, // Adds consistent spacing between the items
          }}
        >
          {/* Left-aligned logo */}
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <img
              src={logo}
              alt={process.env.REACT_APP_COMPANY_NAME}
              height="40"
              onClick={handleLogoClick}
              style={{ cursor: "pointer", marginRight: "4rem" }}
            />
            <Typography
              variant="h5"
              color="primary"
              component="h1"
              className="hidden md:block"
              sx={{
                marginLeft: "auto",
                marginRight: "auto",
                textAlign: "center",
              }}
            >
              {user.company.name}
            </Typography>
          </Box>

          {/* Center-aligned typography */}
          <Box sx={{ display: "flex", alignItems: "center" }}></Box>
          {/* Right-aligned Select and IconButton */}
          <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
            <Typography
              variant="body"
              color="primary"
              className="hidden sm:flex"
              sx={{ textTransform: "uppercase" }}
            >
              Vælg rapportår
            </Typography>
            <Select
              value={accountingYearId}
              onChange={handleYearChange}
              sx={{
                alignSelf: "center", // Aligns the Select vertically in the center
                "& .MuiSelect-select": {
                  paddingTop: "6px", // Adjusts the padding to align with other elements
                  paddingBottom: "6px",
                },
              }}
              inputProps={{ "aria-label": "Regnskabsår" }}
            >
              {accountingYears.map((year) => (
                <MenuItem key={year.id} value={year.id}>
                  {new Date(year.end_date).getFullYear()}
                </MenuItem>
              ))}
            </Select>
            <IconButton color="primary" onClick={handleMenuOpen}>
              <AccountCircle sx={{ fontSize: "1.5em" }} />
            </IconButton>
            <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleMenuClose}
            >
              <MenuItem onClick={handleSettingsClick}>Indstillinger</MenuItem>
              <MenuItem onClick={onToggleDarkMode}>Dark mode</MenuItem>
              <MenuItem onClick={handleLogout}>Log ud</MenuItem>
            </Menu>
          </Box>
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default Navbar;
